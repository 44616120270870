<template>
  <div>
    <CCard>
      <CCardHeader class="d-flex align-items-center">
        <TMessage :content="credit.id" bold noTranslate />
        <span class="mx-1">-</span>
        <SMessageWallet :data="credit.wallet" />
        <TSpinner :loading="loading" />
        <TButtonRefresh
          class="ml-auto"
          @click="$store.dispatch('accounting.credit_orders.detail.fetch', id)"
        />
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol col="12" md="3">
            <CCallout color="info">
              <TMessage content="Sale order" bold color="info" uppercase />
              <TLink
                v-if="!lodash.isEmpty(credit) && credit.transactions.length"
                class="h5"
                :to="
                  lodash.getReferenceLink(
                    'order',
                    credit.transactions[0].receipts[0].receiptable_id
                  )
                "
                :content="credit.transactions[0].receipts[0].receiptable_id"
              />
              <TMessageNotFound v-else />
            </CCallout>
          </CCol>
          <CCol col="12" md="3">
            <CCallout color="info">
              <TMessage
                content="Payment due date"
                bold
                color="info"
                uppercase
              />
              <TMessageDateTime
                :content="credit.payment_due_date"
                :editable="!loading"
                @change="updateCredit('payment_due_date', $event)"
                class="h5"
                dateOnly
              />
            </CCallout>
          </CCol>
          <CCol col="12" md="3">
            <CCallout color="primary">
              <TMessage content="Amount" bold color="primary" uppercase />
              <TMessageMoney
                :amount="credit.amount"
                class="h5"
                :editable="!loading"
                @change="updateCredit('amount', $event)"
                :currency="credit.wallet && credit.wallet.currency_id"
              />
            </CCallout>
          </CCol>
          <CCol col="12" md="3">
            <CCallout color="primary">
              <TTableAsForm
                :fields="creditFields"
                :data="credit"
                :splitLeft="7"
                :addRowClasses="['p-0 text-uppercase']"
              >
                <template #outstanding>
                  <TMessageMoney
                    :amount="credit.outstanding_amount"
                    :currency="credit.wallet && credit.wallet.currency_id"
                  />
                </template>
                <template #paid_amount>
                  <TMessageMoney
                    :amount="credit.paid_amount"
                    :currency="credit.wallet && credit.wallet.currency_id"
                  />
                </template>
                <template #used_amount>
                  <TMessageMoney
                    :amount="credit.used_amount"
                    :currency="credit.wallet && credit.wallet.currency_id"
                  />
                </template>
                <template #remaining_amount>
                  <TMessageMoney
                    :amount="credit.remaining_amount"
                    :currency="credit.wallet && credit.wallet.currency_id"
                  />
                </template>
              </TTableAsForm>
            </CCallout>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <TableTransactions
      v-if="credit.id"
      class="mb-4"
      store="accounting.transactions"
      :default_filter="{
        'filter[receipts.receiptable_id]': id,
        'filter[receipts.receiptable_type]': 'credit_order',
      }"
      :key="`table-transactions-${id}`"
    />
  </div>
</template>

<script>
import TableTransactions from "../../../components/TableTransactions.vue";
import { mapGetters } from "vuex";

export default {
  components: { TableTransactions },
  created() {
    this.$store.dispatch("accounting.credit_orders.detail.fetch", this.id);
  },
  data() {
    return {
      creditFields: [
        { key: "used_amount", label: "Used" },
        { key: "remaining_amount", label: "Remaining" },
        { key: "outstanding", label: "Outstanding" },
        { key: "paid_amount", label: "Paid" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      credit: "accounting.credit_orders.detail",
      loading: "accounting.credit_orders.detail.loading",
    }),
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    updateCredit(field, value) {
      return this.$store.dispatch("accounting.credit_orders.detail.update", {
        [field]: value,
      });
    },
  },
};
</script>
